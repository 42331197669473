<template>
<div>
    <Campaign />
</div>
</template>

<script>
import Campaign from '@/components/Campaign/index'
export default {
    components: {
        Campaign,

    },

}
</script>

<style>

</style>
