<template>
  <div>
    <section class="appie-services-2-area dashboard pb-5">
      <div v-if="isLoggedIn" class="mt-3">
        <button @click="handleLogout">Logout</button>
      </div>
      <div class="container">
        <div class="row mb-4">
          <div class="col-lg-3 col-md-6">
            <card-1></card-1>
          </div>
          <div class="col-lg-3 col-md-6">
            <card-2></card-2>
          </div>
          <div class="col-lg-3 col-md-6">
            <card-3></card-3>
          </div>
          <div class="col-lg-3 col-md-6">
            <card-4></card-4>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-12">
            <div class="contact-form">
              <h5>Transaksi perbulan</h5>
              <span style="font-size: 11px">Total Transaksi perbulan keseluruhan</span>
              <line-chart />
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-6">
            <div class="contact-form" style="height: 100%">
              <h5>Pendapatan</h5>
              <span style="font-size: 11px">Total pendapatan berdasarkan campaign</span>
              <pie-chart />
            </div>
          </div>
          <div class="col-md-6">
            <div class="contact-form" style="height: 100%">
              <h5>Transaksi percampaign</h5>
              <span style="font-size: 11px">Total Transaksi percampaign</span>
              <bar-chart />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Card1 from "@/components/Admin/Dashboard/Card/Card1.vue";
import Card2 from "@/components/Admin/Dashboard/Card/Card2.vue";
import Card3 from "@/components/Admin/Dashboard/Card/Card3.vue";
import Card4 from "@/components/Admin/Dashboard/Card/Card4.vue";
import LineChart from "@/components/Admin/Dashboard/Line/LineChart.vue";
import PieChart from "@/components/Admin/Dashboard/Pie/PieChart.vue";
import BarChart from "@/components/Admin/Dashboard/Bar/BarChart.vue";

export default {
  // Di halaman Dashboard.vue atau di mana saja
  mounted() {
    // Cek apakah pengguna sudah login
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (!isLoggedIn) {
      // Jika belum login, redirect ke halaman login
      this.$router.push("/login");
    }
  },

  components: {
    LineChart,
    PieChart,
    BarChart,
    Card1,
    Card2,
    Card3,
    Card4,
  },

  data() {
    return {};
  },
};
</script>
