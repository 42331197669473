<template>
<div>
    <title-fidyah />
    <kalkulator-fidyah />
    <detail-fidyah />
</div>
</template>

<script>
import TitleFidyah from "./TitleFidyah.vue"
import DetailFidyah from "./DetailFidyah.vue"
import KalkulatorFidyah from "./KalkulatorFidyah.vue"

export default {
    components: {
        TitleFidyah,DetailFidyah,KalkulatorFidyah
    },
   
    data() {
        return {
           
        }
    },
}
</script>

<style scoped>

</style>./DetailFidyah.vue./KalkulatorFidyah.vue./TitleFidyah.vue
