<template>
<div>
    <div class="appie-fun-fact-item">
        <div class="icon">
            <img src="@/assets/images/landing/market/kerjasama.svg" alt="" style="width:70px">
        </div>
        <h5 class="title">
            Rp.{{ formattedTotalSum }}
        </h5>
        <span>Jumlah Donasi</span>
    </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            products: [],
            totalSum: 0,
        };
    },
    computed: {
        formattedTotalSum() {
            return this.totalSum.toLocaleString('id-ID');
        }
    },
    methods: {
        fetchProducts() {
            axios.get(process.env.VUE_APP_SHOPURL + '/api/product?categoryid=1679091c5a880faf6fb5e6087eb1b2dc')
                .then(response => {
                    this.products = response.data.data;
                    this.calculateTotalSum();
                })
                .catch(error => {
                    console.error("There was an error fetching the products!", error);
                });
        },
        calculateTotalSum() {
            this.totalSum = this.products.reduce((sum, product) => {
                return sum + parseInt(product.total.replace(/\./g, ''), 10);
            }, 0);
        }
    },
    created() {
        this.fetchProducts();
    }
};
</script>

<style scoped>
h1 {
    font-family: Arial, sans-serif;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin: 5px 0;
}
</style>'
