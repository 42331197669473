<template>
    <div>
        <div class="row mb-3 pt-2">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="d-lg-flex col-lg-6 justify-content-lg-start">
                    <input type="text" v-model="searchQuery" class="form-control" id="search"
                        placeholder="Enter keyword">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end">
                <div>
                    <button @click="exportToPDF" class="btn btn-danger me-2"><b-icon icon="file-earmark-pdf-fill"
                            variant="light" class="me-2"></b-icon> Export to PDF</button>
                    <button @click="exportToExcel" class="btn btn-success"><b-icon icon="file-excel" variant="light"
                            class="me-2"></b-icon> Export to Excel</button>
                </div>
            </div>
        </div>
        <div class="table-responsive table-sm" v-if="paginationProduks.length > 0">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th @click="sortBy('id')">
                            NO
                            <i v-if="sortByColumn === 'no'"
                                :class="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"></i>
                        </th>

                        <th @click="sortBy('customer_name')">
                            CUSTOMER NAME
                            <i v-if="sortByColumn === 'customer_name'"
                                :class="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"></i>
                        </th>

                        <th @click="sortBy('customer_phone')">
                            PHONE
                            <i v-if="sortByColumn === 'customer_phone'"
                                :class="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"></i>
                        </th>
                        <th @click="sortBy('updated_at')">
                            UPDATE
                            <i v-if="sortByColumn === 'updated_at'"
                                :class="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"></i>
                        </th>
                        <th @click="sortBy('price')">
                            NOMINAL
                            <i v-if="sortByColumn === 'price'"
                                :class="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"></i>
                        </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(settlement, nomor) in paginationProduks" :key="settlement.id">
                        <td>{{ nomor + 1 }}</td>
                        <td>{{ settlement.customer_name }}</td>
                        <td>{{ settlement.customer_phone }}</td>
                        <td>{{ settlement.updated_at }}</td>
                        <td>{{ settlement.price }}</td>
                        <td>
                            <router-link :to="`/detail-wakaf-admin?productid=${settlement.id}`">
                                <button class="btn btn-primary btn-sm ms-1 me-1">
                                    <b-icon icon="cursor-fill" variant="light" class="me-2"></b-icon> Detail
                                </button>
                            </router-link>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="d-flex justify-content-center">TIDAK ADA DATA</div>
        <div>Total Keseluruhan Data: {{ totalProduk }}</div>
        <div class="row mb-3">
            <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                <div class=" filter-control">
                    <b-pagination v-model="currentPage" :total-rows="totalProduk" :per-page="perPage"></b-pagination>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="d-flex justify-content-end">
                    <div class="d-flex align-items-center filter-control">
                        <span class="me-2 ms-2">Show</span>
                        <b-form-select v-model="perPage" :options="perPageOptions"
                            class="mr-3 form-select"></b-form-select>
                        <span class="ms-2">entries</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {
    VMoney
} from 'v-money';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx'; // Import XLSX properly
import {
    saveAs
} from 'file-saver';

export default {
    data() {
        return {
            produkDonasi: {
                data: []
            },
            currentPage: 1,
            perPage: 10,
            perPageOptions: [5, 10, 20, 50],
            searchQuery: '',
            filter: '',
            categories: [],
            sortByColumn: '',
            sortDirection: 'asc',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'Rp ',
                suffix: '',
                precision: 0,
                masked: false
            }
        };
    },
    computed: {
        filteredProducts() {
            let filtered = this.produkDonasi.data;

            const query = this.searchQuery.toLowerCase();
            filtered = filtered.filter(product => product.customer_name.toLowerCase().includes(query));

            if (this.valueurutan === 'Terbaru') {
                filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            } else if (this.valueurutan === 'Terlama') {
                filtered.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            }

            return filtered;
        },
        totalProduk() {
            return this.filteredProducts.length;
        },
        paginationProduks() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            return this.filteredProducts.slice(start, end);
        }
    },
    methods: {
        sortBy(column) {
            if (this.sortByColumn === column) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortByColumn = column;
                this.sortDirection = 'asc';
            }

            this.produkDonasi.data.sort((a, b) => {
                let modifier = 1;
                if (this.sortDirection === 'desc') modifier = -1;
                if (a[column] < b[column]) return -1 * modifier;
                if (a[column] > b[column]) return 1 * modifier;
                return 0;
            });
        },
        fetchProducts(productid) {
            axios.get(`${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=${productid}`)
                .then(response => {
                    if (response.data.status === 200) {
                        this.produkDonasi.data = response.data.data.settlement;
                    }
                })
                .catch(error => {
                    console.error('Error fetching settlement data:', error);
                });
        },
        exportToPDF() {
            const doc = new jsPDF();
            const rows = this.filteredProducts.map((product, index) => [
                index + 1,
                product.customer_name,
                product.customer_phone,
                product.updated_at,
                product.price,
            ]);

            doc.autoTable({
                head: [
                    ['NO', 'NAMA ', 'NO HP', 'UPDATE', 'NOMINAL']
                ],
                body: rows
            });

            doc.save('produk_donasi.pdf');
        },
        exportToExcel() {
            const data = this.filteredProducts.map((product, index) => ({
                NO: index + 1,
                CUSTOMER_NAME: product.customer_name,
                CUSTOMER_PHONE: product.customer_phone,
                UPDATED_AT: product.updated_at,
                PRICE: product.price
            }));

            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Products');
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'produk_donasi.xlsx');
        }
    },
    created() {
        this.productid = this.$route.query.productid;
        if (this.productid) {
            this.fetchProducts(this.productid);
        }

        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    },
    mounted() {
        this.productid = this.$route.query.productid;
        if (this.productid) {
            this.fetchProducts(this.productid);
        }

        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    },
    directives: {
        money: VMoney
    },
};
</script>

<style scoped>
/* Tambahkan styling Bootstrap di sini */
</style>
