<template>
  <div>
    <section class="appie-services-2-area dashboard pb-5">
      <div class="container pt-5">
        <div class="contact-form">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="col-lg-6 justify-content-lg-start">
                <h4>Data Wakaf</h4>
                <p>Daftar Wakaf Dibuat</p>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12 d-lg-flex col-lg-6 justify-content-end"
            >
              <div>
                <button @click="viewDetails(user)" class="btn btn-primary btn-md">
                  <b-icon icon="folder-plus" variant="light" class="me-2"></b-icon>Tambah
                  Data
                </button>
              </div>
            </div>
          </div>

          <DataTable />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DataTable from "./DataTableWakaf.vue";

export default {
  components: {
    DataTable,
  },
  mounted() {
    // Cek apakah pengguna sudah login
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (!isLoggedIn) {
      // Jika belum login, redirect ke halaman login
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
/* Gaya tambahan untuk komponen induk */
</style>
