<template>
<div>
    <div class="appie-fun-fact-item">
        <div class="icon">
            <img src="@/assets/images/landing/market/percaya.svg" alt="" style="width:70px">
        </div>
        <h4 class="title">
   {{ totalCustomerIds }}
        </h4>
        <span>Transaksi</span>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      products: [],
      totalSum: 0,
      totalSettlementSummary: 0,
      totalCustomerIds: 0,
      totalIds: 0,
    };
  },
  computed: {
    formattedTotalSum() {
      return this.totalSum.toLocaleString('id-ID');
    },
    formattedTotalSettlementSummary() {
      return this.totalSettlementSummary.toLocaleString('id-ID');
    }
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SHOPURL}/api/product?categoryid=1679091c5a880faf6fb5e6087eb1b2dc`);
        this.products = response.data.data;
        this.totalIds = this.products.length;
        await this.fetchSettlementSummary();
        this.calculateTotalSum();
      } catch (error) {
        console.error("There was an error fetching the products!", error);
      }
    },
    async fetchSettlementSummary() {
      try {
        let totalCustomerIdsSet = new Set();

        for (let product of this.products) {
          const response = await axios.get(`${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=${product.id}`);
          const settlementData = response.data.data;

          product.settlement_summary = settlementData.settlement_summary;
          product.total_order_ids = new Set(settlementData.settlement.map(trx => trx.order_id)).size;

          settlementData.settlement.forEach(trx => {
            totalCustomerIdsSet.add(trx.order_id);
          });
        }
        
        this.calculateTotalSettlementSummary();
        this.totalCustomerIds = totalCustomerIdsSet.size;
      } catch (error) {
        console.error("There was an error fetching the settlement summary!", error);
      }
    },
    calculateTotalSum() {
      this.totalSum = this.products.reduce((sum, product) => {
        return sum + parseInt(product.total.replace(/\./g, ''), 10);
      }, 0);
    },
    calculateTotalSettlementSummary() {
      this.totalSettlementSummary = this.products.reduce((sum, product) => {
        return sum + parseInt(product.settlement_summary.replace(/\./g, ''), 10);
      }, 0);
    }
  },
  created() {
    this.fetchProducts();
  }
};
</script>

<style scoped>
h1 {
  font-family: Arial, sans-serif;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 5px 0;
}
</style>