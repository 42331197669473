<template>
  <!-- Contact Start -->

  <div class="col-md-12">
    <div class="contact-form text-center pt-5 pb-5">
      <h3>Anda punya pertanyaan?</h3>
      <p>Anda dapat menghubungi kami.</p>

      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="mt-3">
              <div class="b-post-details text-center" style="background: #f8f8f8">
                <i
                  class="fal fa-envelope"
                  style="
                    border-radius: 10px;
                    color: white;
                    font-size: 24px;
                    padding: 10px;
                    background: rgb(208, 163, 42) !important;
                  "
                ></i>
                <h3>sekret@darulquran.sch.id</h3>
                <span>Email Kami</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="mt-3">
              <div class="b-post-details text-center" style="background: #f8f8f8">
                <i
                  class="fal fa-comments"
                  style="
                    border-radius: 10px;
                    color: white;
                    font-size: 24px;
                    padding: 10px;
                    background: rgb(208, 163, 42) !important;
                  "
                ></i>
                <h3>+6281212427687</h3>
                <span>Hubungi Kami</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="mt-3">
              <div class="b-post-details text-center" style="background: #f8f8f8">
                <i
                  class="fal fa-map"
                  style="
                    border-radius: 10px;
                    color: white;
                    font-size: 24px;
                    padding: 10px;
                    background: rgb(208, 163, 42) !important;
                  "
                ></i>
                <h3>Darul Quran Mulia, Bogor</h3>
                <span>Alamat Kami</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Contact End -->
</template>

<script>
export default {};
</script>

<style></style>
