<template>
    <div>

        <!--====== APPIE FUN FACT PART START ======-->

        <section class="appie-fun-fact-area pt-100 pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="appie-fun-fact-box wow animated fadeInUp" data-wow-duration="2000ms"
                            data-wow-delay="400ms">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="appie-fun-fact-content">
                                        <div class="row text-center">
                                            <div class="col-sm-4">
                                                <item-1 />
                                            </div>
                                            <div class="col-sm-4">
                                                <item-2 />
                                            </div>
                                            <div class="col-sm-4">
                                                <item-3 />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--====== APPIE FUN FACT PART ENDS ======-->
    </div>
</template>

<script>
import Item1 from './Market/Item-1.vue'
import Item2 from './Market/Item-2.vue'
import Item3 from './Market/Item-3.vue'
export default {
    components: {
        Item1, Item2, Item3
    },
    data() {
        return {


        }
    }
}
</script>

<style>

</style>
