<template>
    <div>
        <detail-area-wakaf />


        <div class="shop-details-info-area pt-85 pb-100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="shop-details-box">
                            <div class="contact-form ">
                                <table-donatur />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import DetailAreaWakaf from './Detail/DetailAreaWakaf.vue';
import TableDonatur from './Detail/TableDonatur.vue';

export default {
    components: {
        DetailAreaWakaf, TableDonatur 
    },


}
</script>
