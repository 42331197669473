<template>
    <div>
   
        <section class="blogpage-section">
            <div class="container">
            

                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <all-berita />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>


import AllBerita from './AllBerita.vue'
export default {
    components: {
     
   
        AllBerita
    },

    data() {

        return {

            valueurutan: '',
            urutan: ['Terbaru', 'Terlama', 'Waktu Dekat']

        }
    },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
