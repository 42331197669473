<template>
<div>
    <sidebar-home-three :sidebar="sidebar" @toggleSidebar="toggleSidebar" :menuItems="navs" />
    <header-home-three @toggleSidebar="toggleSidebar" :menuItems="navs" />
</div>
</template>

<script>
import HeaderHomeThree from './HeaderHomeThree.vue';
import SidebarHomeThree from '../Sidebar/SidebarHomeThree.vue';

export default {
    components: {
        HeaderHomeThree,
        SidebarHomeThree
    },
    data() {
        return {
            sidebar: false,
            navs: [{
                    name: 'Dashboard',
                    path: '/dashboard'
            },
                {
                    name: 'Data Wakaf',
                    path: '/data-wakaf'
                },

                {
                    name: 'Donatur',
                    childrens: [{
                        name: 'Sedekah',
                        path: '/sedekah-admin'
                    },
                    {
                        name: 'Penghasilan',
                        path: '/penghasilan-admin'
                    },
                       {
                        name: 'Maal',
                        path: '/maal-admin'
                    },
                    {
                        name: 'Fidyah',
                        path: '/fidyah-admin'
                        },
                        {
                            name: 'Fitrah',
                            path: '/fitrah-admin'
                        },


                    ],
                },

            ]
        }
    },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods: {
        topToBottom() {
            const result = document.querySelector('.back-to-top')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar() {
            this.sidebar = !this.sidebar
        },
    }

}
</script>
