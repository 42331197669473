<template>
  <div>
    <div class="row mb-3">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="d-lg-flex col-lg-6 justify-content-lg-start">
          <input
            type="text"
            v-model="searchQuery"
            class="form-control"
            id="search"
            placeholder="Enter keyword"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end">
        <div>
          <label>Filter : </label>
          <select v-model="filter" class="form-control" id="filter">
            <option value="">All</option>
            <option v-for="category in categories" :key="category">{{ category }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="table-responsive table-sm" v-if="filteredProducts.length > 0">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th @click="sortBy('id')">
              NO
              <i
                v-if="sortByColumn === 'no'"
                :class="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"
              ></i>
            </th>
            <th @click="sortBy('name')">
              PRODUK
              <i
                v-if="sortByColumn === ''"
                :class="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"
              ></i>
            </th>
            <th @click="sortBy('total')">
              TOTAL
              <i
                v-if="sortByColumn === ''"
                :class="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"
              ></i>
            </th>

            <th>Action</th>
            <!-- Kolom untuk tombol aksi -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, nomor) in filteredProducts" :key="product.id">
            <td>{{ nomor + 1 }}</td>
            <td>{{ product.product }}</td>
            <td>Rp. {{ product.total }}</td>
            <td>
              <!-- Tombol aksi -->
              <router-link :to="`/detail-wakaf-admin?productid=${product.id}`">
                <button class="btn btn-primary btn-sm ms-1 me-1">
                  <b-icon icon="cursor-fill" variant="light" class="me-2"></b-icon> Detail
                </button>
              </router-link>

              <button @click="editUser(user)" class="btn btn-warning btn-sm ms-1 me-1">
                <b-icon icon="cursor-text" variant="light" class="me-2"></b-icon>Edit
              </button>
              <button @click="archiveUser(user)" class="btn btn-danger btn-sm ms-1 me-1">
                <b-icon icon="pause-circle-fill" variant="light" class="me-2"></b-icon
                >Archive
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="d-flex justify-content-center">TIDAK ADA DATA</div>
    <div>Total Keseluruhan Data: {{ totalProduk }}</div>
    <!-- Menampilkan total keseluruhan data -->

    <div class="row mb-3">
      <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
        <div class="filter-control">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProduk"
            :per-page="perPage"
          ></b-pagination>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="d-flex justify-content-end">
          <div class="d-flex align-items-center filter-control">
            <span class="me-2 ms-2">Show</span>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="mr-3 form-select"
            ></b-form-select>
            <span class="ms-2">entries</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      produkDonasi: {
        data: [],
      },
      currentPage: 1,
      perPage: 10,
      perPageOptions: [5, 10, 20, 50],
      searchQuery: "",
      filter: "",
      categories: [], // Menyimpan daftar kategori yang ada di data
      sortByColumn: "", // Kolom yang dipilih untuk pengurutan
      sortDirection: "asc", // Arah pengurutan (asc/desc)
    };
  },
  computed: {
    filteredProducts() {
      let filtered = this.produkDonasi.data;
      // Filter based on search query
      const query = this.searchQuery.toLowerCase();
      filtered = filtered.filter((product) =>
        product.product.toLowerCase().includes(query)
      );

      // Sort based on selected option
      if (this.valueurutan === "Terbaru") {
        filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
      } else if (this.valueurutan === "Terlama") {
        filtered.sort((a, b) => new Date(a.date) - new Date(b.date));
      }

      return filtered;
    },
    totalProduk() {
      return this.filteredProducts.length;
    },
    paginationProduks() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredProduct.slice(start, end);
    },
    filteredProduct() {
      let filtered = this.product;

      // Filter berdasarkan kategori jika ada yang dipilih
      if (this.filter !== "") {
        filtered = filtered.filter((product) => product.category === this.filter);
      }

      // Filter berdasarkan pencarian
      if (this.searchQuery !== "") {
        filtered = filtered.filter(
          (product) =>
            product.product.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            product.desc.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      return filtered;
    },
  },
  methods: {
    sortBy(column) {
      if (this.sortByColumn === column) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortByColumn = column;
        this.sortDirection = "asc";
      }

      // Urutkan data
      this.filteredProduct.sort((a, b) => {
        let modifier = 1;
        if (this.sortDirection === "desc") modifier = -1;
        if (a[column] < b[column]) return -1 * modifier;
        if (a[column] > b[column]) return 1 * modifier;
        return 0;
      });
    },
    searchProducts() {
      axios
        .get(process.env.VUE_APP_SHOPURL + "/api/product", {
          params: {
            categoryid: "1679091c5a880faf6fb5e6087eb1b2dc",
            search: this.searchQuery,
            sort: this.valueurutan === "Terbaru" ? "desc" : "asc",
          },
        })
        .then((response) => {
          this.produkDonasi = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    fetchProducts() {
      axios
        .get(
          process.env.VUE_APP_SHOPURL +
            "/api/product?categoryid=1679091c5a880faf6fb5e6087eb1b2dc"
        )
        .then((response) => {
          this.produkDonasi = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
  created() {
    axios
      .get(
        process.env.VUE_APP_SHOPURL +
          "/api/product?categoryid=1679091c5a880faf6fb5e6087eb1b2dc"
      )
      .then((response) => {
        this.produkDonasi = response.data;
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  mounted() {
    this.fetchProducts();
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (!isLoggedIn) {
      // Jika belum login, redirect ke halaman login
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
/* Tambahkan styling Bootstrap di sini */
</style>
