<template>
  <div>
    <div class="blog-sidebar">
      <aside class="widget widget-trend-post">
        <h3 class="widget-title">Berita Lainnya</h3>
        <div v-if="loading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-if="!loading && posts.length > 0" class="row">
          <div v-for="(post, index) in paginatedPosts" :key="index">
            <div class="popular-post align-self-center">
              <a :href="`/detail-berita/${post.id}`">
                <img :src="getImageUrl(post)" alt="Post Image" />
                <h5>
                  {{ post?.title?.rendered }}
                </h5>
              </a>
            </div>
          </div>
        </div>
        <div v-else-if="!loading && posts.length === 0" class="row">
          <div class="py-5 text-center">
            <div class="container">
              <div class="col-lg-12">
                <h3 class="text-dqm">Mohon maaf !</h3>
                <span>Data Tidak Tersedia</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Show error message if there was an error fetching data -->
        <div v-else-if="error" class="row">
          <div class="py-5 text-center">
            <div class="container">
              <div class="col-lg-12">
                <h3 class="text-dqm">Mohon maaf !</h3>
                <span>Data Tidak Tersedia</span>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      posts: [],
      images: {},
      currentPage: 1,
      postsPerPage: 6,
      loading: true,
      error: false,
    };
  },
  computed: {
    paginatedPosts() {
      const startIndex = (this.currentPage - 1) * this.postsPerPage;
      return this.posts.slice(startIndex, startIndex + this.postsPerPage);
    },
    totalPages() {
      return Math.ceil(this.posts.length / this.postsPerPage);
    },
  },
  methods: {
    fetchPosts() {
      this.loading = true;
      this.error = false;
      axios
        .get(
          `${process.env.VUE_APP_BERITAURL}/wordpressapi/wp/v2/posts?_fields=date_gmt,excerpt,title,link,slug,id&categories=1&20&tags=30420&per_page=6`
        )
        .then((response) => {
          this.posts = response.data;
          this.posts.forEach((post) => {
            this.fetchImageForPost(post.id);
          });
        })
        .catch((error) => {
          console.error("Error fetching posts:", error);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchImageForPost(postId) {
      axios
        .get(
          `${process.env.VUE_APP_BERITAURL}/wordpressapi/wp/v2/media?_fields=id,guid,media_type&parent=${postId}`
        )
        .then((response) => {
          if (response.data.length > 0) {
            this.$set(this.images, postId, response.data[0].guid.rendered);
          }
        })
        .catch((error) => {
          console.error("Error fetching image for post ID:", postId, error);
        });
    },
    getImageUrl(post) {
      return this.images[post.id] || require("@/assets/images/blog/no-image.png");
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("id-ID", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  mounted() {
    this.fetchPosts();
  },
};
</script>
