<template>
  <!--====== PART START ======-->

  <header class="appie-header-area-admin appie-header-3-area appie-sticky">
    <div class="container">
      <div class="header-nav-box header-nav-box-3">
        <div class="row align-items-center">
          <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
            <div class="appie-logo-box">
              <router-link to="/dashboard">
                <img
                  src="@/assets/images/logo/default.png"
                  alt="logo"
                  style="height: 50px"
                />
              </router-link>
            </div>
          </div>

          <div class="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
            <div class="appie-header-main-menu">
              <nav-items :menuItems="menuItems" :nasted="nasted" />
            </div>
          </div>

          <div class="col-lg-4 col-md-7 col-sm-6 col-6 order-2 order-sm-3">
            <div class="appie-btn-box" style="text-align: right">
              <div class="d-lg-block d-none">
                <a
                  href="/"
                  class="btn text-white"
                  style="background: rgb(208, 163, 42) !important"
                  ><i class="fal fa-home me-2"></i> Landing</a
                >

                <a v-if="isLoggedIn">
                  <a
                    @click="logout"
                    class="btn ml-10 text-white"
                    style="background: rgb(165, 37, 2) !important"
                    ><i class="fa fa-sign-out text-white"></i>
                  </a>
                </a>
              </div>

              <div class="d-lg-none d-block">
                <a
                  href="/"
                  class="btn text-white text-center"
                  style="background: rgb(208, 163, 42) !important"
                  ><i class="fal fa-home"></i
                ></a>

                <a v-if="isLoggedIn">
                  <a
                    @click="logout"
                    class="btn ml-10 text-white text-center"
                    style="background: rgb(165, 37, 2) !important"
                    ><i class="fa fa-sign-out text-white"></i>
                  </a>
                </a>
              </div>

              <div class="toggle-btn ml-30 canvas_open d-lg-none d-block">
                <i class="fa fa-bars" @click="showSidebar"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!--====== PART ENDS ======-->
</template>

<script>
import NavItems from "../NavItem/NavItems.vue";
export default {
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("isLoggedIn");
    },
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    nasted: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NavItems,
  },
  mounted() {
    document.addEventListener("scroll", this.stickMenu);
    this.checkLoginStatus(); // Check login status when component is mounted
  },
  methods: {
    showSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    stickMenu() {
      const result = document.querySelector(".appie-sticky");
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        result.classList.add("sticky");
      } else {
        result.classList.remove("sticky");
      }
    },
    logout() {
      localStorage.removeItem("isLoggedIn");
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
