<template>
  <div>
    <section class="blogpage-section">
      <div class="container">
        <div class="row">
          <!-- Main Content -->
          <div class="col-lg-8 col-md-7">
            <!-- Loading Spinner -->
            <div v-if="loading" class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>

            <!-- Error Message -->
            <div v-else-if="hasError" class="row">
              <div class="py-5 text-center">
                <div class="container">
                  <div class="col-lg-12">
                    <h3 class="text-dqm">Mohon maaf!</h3>
                    <span>Terjadi kesalahan saat memuat data.</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- No Data Message -->
            <div v-else-if="!loading && !post" class="row">
              <div class="py-5 text-center">
                <div class="container">
                  <div class="col-lg-12">
                    <h3 class="text-dqm">Mohon maaf!</h3>
                    <span>Data Tidak Tersedia</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Post Content -->
            <div v-else class="single-post-area">
              <div class="contact--info-area">
                <h3 class="lh-sm">{{ post?.title?.rendered }}</h3>
                <p class="post-date">{{ formattedDate }}</p>
              </div>

              <div class="post-thumb mt-3">
                <p v-html="post?.content?.rendered"></p>
              </div>
            </div>
          </div>

          <!-- Sidebar -->
          <div class="col-lg-4 col-md-7">
            <div class="position-sticky" style="top: 2rem">
              <side-berita />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SideBerita from "@/components/Berita/SideBerita.vue";
import axios from "axios";

export default {
  components: {
    SideBerita,
  },
  data() {
    return {
      post: null,
      loading: true,
      hasError: false,
    };
  },
  computed: {
    postImage() {
      // Extract the first image from the post content
      const imageMatch = this.post?.content?.rendered.match(/<img.*?src="(.*?)"/);
      return imageMatch ? imageMatch[1] : "";
    },
    formattedDate() {
      if (this.post?.date_gmt) {
        // Create a new Date object from the date_gmt
        const date = new Date(this.post.date_gmt);
        // Format the date in Indonesian style
        return date.toLocaleDateString("id-ID", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
      return "";
    },
  },

  async created() {
    // Get the post id from the route params
    const postId = this.$route.params.id;
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BERITAURL}/wordpressapi/wp/v2/posts/${postId}`
      );
      this.post = response.data;
      if (!this.post) {
        // Handle case where post is empty
        this.hasError = true;
      }
    } catch (error) {
      console.error("Error fetching post data:", error);
      this.hasError = true;
    } finally {
      this.loading = false;
    }
  },
};
</script>
