<template>
  <div>
    <line-chart v-if="chartData" :chart-data="chartData" :width="width" :height="height"></line-chart>
  </div>
</template>

<script>
import axios from 'axios';
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

export default {
  extends: Line,
  data() {
    return {
      products: [],
      totalSum: 0,
      totalSettlementSummary: 0,
      totalCustomerIds: 0,
      totalIds: 0,
      monthlyData: {},
      chartData: null // Data to be passed to the chart component
    };
  },
   props: {

    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 300
    },

    },
  computed: {
    formattedTotalSum() {
      return this.totalSum.toLocaleString('id-ID');
    },
    formattedTotalSettlementSummary() {
      return this.totalSettlementSummary.toLocaleString('id-ID');
    }
  },
  chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SHOPURL}/api/product?categoryid=1679091c5a880faf6fb5e6087eb1b2dc`);
        this.products = response.data.data;
        this.totalIds = this.products.length;
        await this.fetchSettlementSummary();
      } catch (error) {
        console.error("There was an error fetching the products!", error);
      }
    },
    async fetchSettlementSummary() {
      try {
        let totalCustomerIdsSet = new Set();
        const productApiUrls = [
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=a684eceee76fc522773286a895bc8436`,
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=d9d4f495e875a2e075a1a4a6e1b9770f`,
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=642e92efb79421734881b53e1e1b18b6`,
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=d82c8d1619ad8176d665453cfb2e55f0`,
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=2838023a778dfaecdc212708f721b788`
        ];

        const responses = await Promise.all(productApiUrls.map(url => axios.get(url)));

        responses.forEach(response => {
          const settlementData = response.data.data;
          settlementData.settlement.forEach(trx => {
            totalCustomerIdsSet.add(trx.order_id);
            if (trx.price) {
              const price = parseInt(trx.price.replace(/\./g, ''), 10);
              this.totalSum += price;
              const updatedAt = new Date(trx.updated_at);
              const year = updatedAt.getFullYear();
              const monthYear = `${year}-${updatedAt.getMonth() + 1}`; // Convert to sortable format: "YYYY-MM"

              if (!this.monthlyData[monthYear]) {
                this.monthlyData[monthYear] = 0;
              }
              this.monthlyData[monthYear] += price;
            }
          });
        });

        // Calculate settlement summaries for each product separately
        for (let product of this.products) {
          const response = await axios.get(`${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=${product.id}`);
          const settlementData = response.data.data;

          product.settlement_summary = settlementData.settlement_summary;
          product.total_order_ids = new Set(settlementData.settlement.map(trx => trx.order_id)).size;

          settlementData.settlement.forEach(trx => {
            totalCustomerIdsSet.add(trx.order_id);
            if (trx.price) {
              const price = parseInt(trx.price.replace(/\./g, ''), 10);
              this.totalSum += price;
              const updatedAt = new Date(trx.updated_at);
              const year = updatedAt.getFullYear();
              const monthYear = `${year}-${updatedAt.getMonth() + 1}`; // Convert to sortable format: "YYYY-MM"

              if (!this.monthlyData[monthYear]) {
                this.monthlyData[monthYear] = 0;
              }
              this.monthlyData[monthYear] += price;
            }
          });
        }

        this.totalCustomerIds = totalCustomerIdsSet.size;
        this.calculateTotalSettlementSummary();
        this.prepareChartData();
      } catch (error) {
        console.error("There was an error fetching the settlement summary!", error);
      }
    },
    calculateTotalSettlementSummary() {
      this.totalSettlementSummary = this.products.reduce((sum, product) => {
        return sum + parseInt(product.settlement_summary.replace(/\./g, ''), 10);
      }, 0);
    },
    prepareChartData() {
      console.log("Monthly Data for Chart:", this.monthlyData); // Debug log for monthlyData
      const sortedKeys = Object.keys(this.monthlyData).sort();
      this.chartData = {
        labels: sortedKeys,
        datasets: [
          {
            label: 'Transaksi perbulan',
            data: sortedKeys.map(key => this.monthlyData[key]),
            backgroundColor: 'rgba(75, 192, 192, 1)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2
          }
        ]
      };
    }
  },
  created() {
    this.fetchProducts();
  }
};
</script>