// akun.js
export const akun = {
  username: "admin",  // Ganti dengan username yang diinginkan
  password: "dqmpeduli99",  // Ganti dengan password yang diinginkan

  login(inputUsername, inputPassword) {
    return (
      inputUsername === this.username && inputPassword === this.password
    );
  },
};
