<template>
<div>
    <index-penghasilan />
</div>
</template>

<script>
import IndexPenghasilan from '@/components/Zakat/Penghasilan/IndexPenghasilan.vue'

export default {
    components: {
        IndexPenghasilan,

    },
    data() {
        return {

        }
    },
}
</script>
