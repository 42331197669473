<template>
    <div>
        <div v-if="!isLoading">
            <section class="shop-details-area pt-100 pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="shop-details-thumb">
                                <div class="shop-details-thumb-slider-active">
                                    <img :src="product.url" alt="" class="w-100 rounded">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="shop-product-details-content pl-70 mt-35"><span>WAKAF</span>
                                <h2 class="title">{{ product.product }}</h2>
                                <div class="pricing">
                                    <div class="discount-price mr-15">Rp. {{ product.total }}</div>
                                </div>
                                <div class="review">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star-half-alt"></i></li>
                                    </ul>
                                </div>
                                <div class="details-info">
                                    <ul>
                                        <li><span>{{ product.desc }} </span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <div class="py-5 text-center">
                <div class="container">
                    <div class="col-lg-12">
                        <b-spinner label="Loading..." variant="dark"></b-spinner>
                        <h3 class="text-dqm"></h3>
                        <span>Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import axios from "axios";

export default {
    components: {
    
    },
    data() {
        return {

            value: 100,
            max: 100,
            product: null,
            productid: null,
            donationAmount: 0,
            isLoading: true,
            shopAPI: process.env.VUE_APP_SHOPURL,
        }
    },
    mounted() {
        // Mendapatkan productid dari URL menggunakan Vue Router
        this.productid = this.$route.query.productid;
        if (this.productid) {
            this.fetchProduct(this.productid);
        }

        setTimeout(() => {
            this.isLoading = false;
        }, 1000)

    },
    methods: {
        async fetchProduct(productid) {
            try {
                const response = await axios.get(`https://shop.dq.akses.live/api/product?productid=${productid}`);
                this.product = response.data.data[0];
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        },

    },

}
</script>
