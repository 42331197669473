<template>
<div>
    <home-Three />
</div>
</template>

<script>
import HomeThree from '../components/Landing/index'

export default {
    components: {
        HomeThree,
    },

}
</script>

<style>

</style>
