<template>
  <div>
    <div class="col-lg-12">
      <!-- Search Bar -->
      <div class="search-bar mb-4">
        <input
          v-model="searchQuery"
          @input="handleSearch"
          class="form-control"
          type="text"
          placeholder="Search by title..."
        />
      </div>

      <!-- Loading Spinner -->
      <div v-if="loading" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <!-- Display posts -->
      <div v-if="!loading && filteredPosts.length > 0" class="row">
        <div v-for="(post, index) in paginatedPosts" :key="index" class="col-lg-4 mb-4">
          <div class="post-item-1" style="height: 100%">
            <img :src="getImageUrl(post)" alt="Post Image" style="aspect-ratio: 4/3" />
            <div class="b-post-details">
              <div class="bp-meta">
                <a href="#">
                  <i class="fal fa-clock"></i>{{ formatDate(post?.date_gmt) }}
                </a>
              </div>
              <h3>
                <router-link :to="`/detail-berita/${post.id}`">
                  {{ post?.title?.rendered }}
                </router-link>
              </h3>
            </div>
          </div>
        </div>
      </div>

      <!-- No posts available -->
      <div v-else-if="!loading && filteredPosts.length === 0" class="row">
        <div class="py-5 text-center">
          <div class="container">
            <div class="col-lg-12">
              <h3 class="text-dqm">Mohon maaf !</h3>
              <span>Data Tidak Tersedia</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Error message -->
      <div v-else-if="error" class="row">
        <div class="py-5 text-center">
          <div class="container">
            <div class="col-lg-12">
              <h3 class="text-dqm">Mohon maaf !</h3>
              <span>Data Tidak Tersedia</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div
        v-if="!loading && filteredPosts.length > 0"
        class="bisylms-pagination text-center"
        style="cursor: pointer"
      >
        <span v-for="page in totalPages" :key="page">
          <a @click="changePage(page)" :class="{ current: currentPage === page }">
            {{ page }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      posts: [],
      images: {},
      currentPage: 1,
      postsPerPage: 6,
      searchQuery: "", // Add search query
      loading: true,
      error: false,
    };
  },
  computed: {
    filteredPosts() {
      if (!this.searchQuery) {
        return this.posts;
      }
      const query = this.searchQuery.toLowerCase();
      return this.posts.filter((post) =>
        post.title.rendered.toLowerCase().includes(query)
      );
    },
    paginatedPosts() {
      const startIndex = (this.currentPage - 1) * this.postsPerPage;
      return this.filteredPosts.slice(startIndex, startIndex + this.postsPerPage);
    },
    totalPages() {
      return Math.ceil(this.filteredPosts.length / this.postsPerPage);
    },
  },
  methods: {
    fetchPosts() {
      this.loading = true;
      this.error = false;
      axios
        .get(
          `${process.env.VUE_APP_BERITAURL}/wordpressapi/wp/v2/posts?_fields=date_gmt,excerpt,title,link,slug,id&categories=1&20&tags=30420&per_page=100&page=1`
        )
        .then((response) => {
          this.posts = response.data;
          this.posts.forEach((post) => {
            this.fetchImageForPost(post.id);
          });
        })
        .catch((error) => {
          console.error("Error fetching posts:", error);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchImageForPost(postId) {
      axios
        .get(
          `${process.env.VUE_APP_BERITAURL}/wordpressapi/wp/v2/media?_fields=id,guid,media_type&parent=${postId}`
        )
        .then((response) => {
          if (response.data.length > 0) {
            this.$set(this.images, postId, response.data[0].guid.rendered);
          }
        })
        .catch((error) => {
          console.error("Error fetching image for post ID:", postId, error);
        });
    },
    getImageUrl(post) {
      return this.images[post.id] || require("@/assets/images/blog/no-image.png");
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("id-ID", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    handleSearch() {
      this.currentPage = 1; // Reset to the first page on search
    },
  },
  mounted() {
    this.fetchPosts();
  },
};
</script>

<style scoped></style>
