<template>
  <section class="appie-about-8-area pt-50 pb-80">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="d-none d-lg-block d-flex justify-content-center align-items-center">
            <img
              src="@/assets/images/landing/market/fundraiser.png"
              alt=""
              style="width: 100%"
            />
          </div>
        </div>
        <div class="col-md-8 col-12">
          <div class="contact-form pt-2">
            <div class="logo mb-5 text-center">
              <a href="https://wa.me/6281212427687?" target="_blank">
                <img
                  src="@/assets/images/logo/footer-logo.png"
                  alt="footer logo"
                  style="height: 70px"
                />
              </a>
            </div>
            <h4>Login</h4>
            <p>Masukan Username dan Password</p>
            <form @submit.prevent="handleLogin" class="row">
              <div class="col-md-12">
                <input type="text" v-model="username" placeholder="Username" required />
              </div>

              <div class="col-md-12">
                <input type="text" v-model="password" placeholder="Password" required />
              </div>

              <div class="col-md-12 text-right">
                <input type="submit" name="submit" value="Submit" />
              </div>
            </form>

            <div v-if="error" class="alert alert-danger mt-3">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { akun } from "@/components/akun.js";

export default {
  data() {
    return {
      username: "",
      password: "",
      error: null,
    };
  },
  methods: {
    handleLogin() {
      // Cek apakah username dan password sesuai dengan yang ada di akun.js
      if (akun.login(this.username, this.password)) {
        // Simpan status login di localStorage
        localStorage.setItem("isLoggedIn", true);
        // Redirect ke halaman dashboard
        this.$router.push("/dashboard");
      } else {
        this.error = "Username atau Password salah!";
      }
    },
  },
  mounted() {
    // Jika sudah login, redirect langsung ke dashboard
    if (localStorage.getItem("isLoggedIn")) {
      this.$router.push("/dashboard");
    }
  },
};
</script>

<style scoped>
/* Add styling here if needed */
</style>
