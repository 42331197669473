<template>
    <div>
        <div class="appie-admin-item mt-30" style="background:#A52502">
            <div class="thumb">
                <b-icon icon="calendar-check" scale="2" variant="light"></b-icon>
            </div>
            <div class="content">
                <h4 class="title">{{ formattedTotalSettlementDays }}</h4>
                <div class="meta-item">
                    <ul>
                        <li><i class="fa fa-calendar" aria-hidden="true"></i> TRANSAKSI HARI INI</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</template><script>
import axios from 'axios';

export default {
    data() {
        return {
            products: [],
            totalCustomerIds: 0,
            totalSettlementDays: 0,
        };
    },
    computed: {
        formattedTotalCustomerIds() {
            return isNaN(this.totalCustomerIds) ? 0 : this.totalCustomerIds;
        },
        formattedTotalSettlementDays() {
            return isNaN(this.totalSettlementDays) ? 0 : this.totalSettlementDays;
        }
    },
    methods: {
        async fetchProducts() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_SHOPURL}/api/product?categoryid=1679091c5a880faf6fb5e6087eb1b2dc`);
                this.products = response.data.data;
                await this.fetchSettlementSummary();
            } catch (error) {
                console.error("There was an error fetching the products!", error);
            }
        },
        async fetchSettlementSummary() {
            try {
                const productIds = [
                    'a684eceee76fc522773286a895bc8436',
                    'd9d4f495e875a2e075a1a4a6e1b9770f',
                    '642e92efb79421734881b53e1e1b18b6',
                    'd82c8d1619ad8176d665453cfb2e55f0',
                    '2838023a778dfaecdc212708f721b788'
                ];

                const requests = productIds.map(id => axios.get(`${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=${id}`));
                const responses = await axios.all(requests);

                let totalCustomerIdsSet = new Set();
                let totalSettlementDays = 0;

                responses.forEach(response => {
                    const settlementData = response.data.data.settlement;
                    settlementData.forEach(trx => {
                        totalCustomerIdsSet.add(trx.order_id);
                        totalSettlementDays += parseInt(trx.settlement_day) || 0;
                    });
                });

                this.totalCustomerIds = totalCustomerIdsSet.size;
                this.totalSettlementDays = totalSettlementDays;
            } catch (error) {
                console.error("There was an error fetching the settlement summary!", error);
            }
        },
    },
    created() {
        this.fetchProducts();
    }
};
</script>

<style scoped>
h1 {
    font-family: Arial, sans-serif;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin: 5px 0;
}
</style>