<template>
  <div>
    <bar-chart-component :chart-data="chartData" :options="chartOptions" :height="height"></bar-chart-component>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default {
  name: 'BarChart',
  components: {
    'bar-chart-component': Bar
  },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    }
  }
}
</script>


