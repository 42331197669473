<template>
<div>
    <Berita />
</div>
</template>

<script>
import Berita from '@/components/Berita/index'
export default {
    components: {
        Berita,
    },

}
</script>

<style></style>
