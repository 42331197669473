<template>
<div>
    <index-maal />
</div>
</template>

<script>
import IndexMaal from '@/components/Zakat/Maal/IndexMaal.vue'

export default {
    components: {
        IndexMaal,

    },
    data() {
        return {

        }
    },
}
</script>
