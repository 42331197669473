<template>
  <div>
    <!-- Include the BarChart component -->
    <bar-chart v-if="chartData" :chart-data="chartData" :height="height"></bar-chart>
  </div>
</template>

<script>
import axios from "axios";
import BarChart from "./Trans.vue";

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      products: [],
      totalSum: 0,
      totalSettlementSummary: 0,
      totalCustomerIds: 0,
      totalIds: 0,
      chartData: null,
    };
  },
  computed: {
    formattedTotalSum() {
      return this.totalSum.toLocaleString("id-ID");
    },
    formattedTotalSettlementSummary() {
      return this.totalSettlementSummary.toLocaleString("id-ID");
    },
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_SHOPURL}/api/product?categoryid=1679091c5a880faf6fb5e6087eb1b2dc`
        );
        this.products = response.data.data;
        this.totalIds = this.products.length;
        await this.fetchSettlementSummary();
        this.calculateTotalSum();
        this.prepareChartData();
      } catch (error) {
        console.error("There was an error fetching the products!", error);
      }
    },
    async fetchSettlementSummary() {
      try {
        let totalCustomerIdsSet = new Set();

        const productApiUrls = [
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=a684eceee76fc522773286a895bc8436`,
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=d9d4f495e875a2e075a1a4a6e1b9770f`,
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=642e92efb79421734881b53e1e1b18b6`,
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=d82c8d1619ad8176d665453cfb2e55f0`,
          `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=2838023a778dfaecdc212708f721b788`,
        ];

        const responses = await Promise.all(productApiUrls.map((url) => axios.get(url)));

        responses.forEach((response) => {
          const settlementData = response.data.data;

          settlementData.settlement.forEach((trx) => {
            totalCustomerIdsSet.add(trx.order_id);
          });
        });

        for (let product of this.products) {
          const response = await axios.get(
            `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=${product.id}`
          );
          const settlementData = response.data.data;

          product.settlement_summary = settlementData.settlement_summary;
          product.total_order_ids = new Set(
            settlementData.settlement.map((trx) => trx.order_id)
          ).size;

          settlementData.settlement.forEach((trx) => {
            totalCustomerIdsSet.add(trx.order_id);
          });
        }

        this.calculateTotalSettlementSummary();
        this.totalCustomerIds = totalCustomerIdsSet.size;
      } catch (error) {
        console.error("There was an error fetching the settlement summary!", error);
      }
    },
    calculateTotalSum() {
      this.totalSum = this.products.reduce((sum, product) => {
        return sum + parseInt(product.total.replace(/\./g, ""), 10);
      }, 0);
    },
    calculateTotalSettlementSummary() {
      this.totalSettlementSummary = this.products.reduce((sum, product) => {
        return sum + parseInt(product.settlement_summary.replace(/\./g, ""), 10);
      }, 0);
    },
    prepareChartData() {
      this.chartData = {
        labels: this.products.map((product) => product.product),
        datasets: [
          {
            label: "Number of Transactions",
            backgroundColor: "#42A5F5",
            data: this.products.map((product) => product.total_order_ids),
          },
        ],
      };
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>
