<template>
<div>
    <index-sedekah />
</div>
</template>

<script>
import IndexSedekah from '@/components/Sedekah/IndexSedekah.vue'

export default {
    components: {
        IndexSedekah,

    },
    data() {
        return {

        }
    },
}
</script>
