<template>
<div>
    <index-fitrah />
</div>
</template>

<script>
import IndexFitrah from '@/components/Zakat/Fitrah/IndexFitrah.vue'

export default {
    components: {
        IndexFitrah,

    },
    data() {
        return {

        }
    },
}
</script>
