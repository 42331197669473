<template>
  <div class="chart-container">
    <pie-chart v-if="chartData" :chart-data="chartData"></pie-chart>
  </div>
</template>

<script>
import axios from "axios";
import { Pie } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default {
  extends: Pie,
  data() {
    return {
      products: [],
      totalSum: 0,
      totalSettlementSummary: 0,
      totalCustomerIds: 0,
      totalIds: 0,
      chartData: null, // Data to be passed to the chart component
    };
  },
  computed: {
    formattedTotalSum() {
      return this.totalSum.toLocaleString("id-ID");
    },
    formattedTotalSettlementSummary() {
      return this.totalSettlementSummary.toLocaleString("id-ID");
    },
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_SHOPURL}/api/product?categoryid=1679091c5a880faf6fb5e6087eb1b2dc`
        );
        this.products = response.data.data;
        this.totalIds = this.products.length;
        await this.fetchSettlementSummary();
      } catch (error) {
        console.error("There was an error fetching the products!", error);
      }
    },
    async fetchSettlementSummary() {
      try {
        let totalCustomerIdsSet = new Set();
        const productApiUrls = this.products.map(
          (product) =>
            `${process.env.VUE_APP_SHOPURL}/api/transaction/summary?productid=${product.id}`
        );

        const responses = await Promise.all(productApiUrls.map((url) => axios.get(url)));

        responses.forEach((response, index) => {
          const settlementData = response.data.data;
          const product = this.products[index];

          product.settlement_summary = settlementData.settlement_summary;
          product.total_order_ids = new Set(
            settlementData.settlement.map((trx) => trx.order_id)
          ).size;

          settlementData.settlement.forEach((trx) => {
            totalCustomerIdsSet.add(trx.order_id);
            if (trx.price) {
              const price = parseInt(trx.price.replace(/\./g, ""), 10);
              this.totalSum += price;
            }
          });
        });

        this.totalCustomerIds = totalCustomerIdsSet.size;
        this.calculateTotalSettlementSummary();
        this.prepareChartData();
      } catch (error) {
        console.error("There was an error fetching the settlement summary!", error);
      }
    },
    calculateTotalSettlementSummary() {
      this.totalSettlementSummary = this.products.reduce((sum, product) => {
        return sum + parseInt(product.settlement_summary.replace(/\./g, ""), 10);
      }, 0);
    },
    prepareChartData() {
      console.log("Preparing Chart Data based on Settlement Summary and Product Names");
      this.chartData = {
        labels: this.products.map((product) => product.product),
        datasets: [
          {
            label: "Settlement Summary",
            data: this.products.map((product) =>
              parseInt(product.settlement_summary.replace(/\./g, ""), 10)
            ),
            backgroundColor: [
              "rgba(75, 192, 192, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 205, 86, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 205, 86, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>

<style scoped>
.chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px; /* Atur tinggi */
}
</style>
