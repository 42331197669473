<template>
<div>
    <index-fidyah />
</div>
</template>

<script>
import IndexFidyah from '@/components/Zakat/Fidyah/IndexFidyah.vue'

export default {
    components: {
        IndexFidyah,

    },
    data() {
        return {

        }
    },
}
</script>
