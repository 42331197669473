<template>
    <div>
        <div class="appie-admin-item mt-30" style="background:#6dd4b1">
            <div class="thumb">
                <b-icon icon="bar-chart-fill" scale="2" variant="light"></b-icon>
            </div>
            <div class="content">
                <h5 class="title">{{ totalIds }}</h5>
                <div class="meta-item">
                    <ul>
                        <li><i class="fa fa-user" aria-hidden="true"></i> JUMLAH
                            CAMPAIGN</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            products: [],
            totalSum: 0,
            totalIds: 0,
        };
    },
    computed: {
        formattedTotalSum() {
            return this.totalSum.toLocaleString('id-ID');
        }
    },
    methods: {
        fetchProducts() {
            axios.get(process.env.VUE_APP_SHOPURL + '/api/product?categoryid=1679091c5a880faf6fb5e6087eb1b2dc')
                .then(response => {
                    this.products = response.data.data;
                    this.calculateTotalSum();
                    this.calculateTotalIds();
                })
                .catch(error => {
                    console.error("There was an error fetching the products!", error);
                });
        },
        calculateTotalSum() {
            this.totalSum = this.products.reduce((sum, product) => {
                return sum + parseInt(product.total.replace(/\./g, ''), 10);
            }, 0);
        },
        calculateTotalIds() {
            this.totalIds = this.products.length;
        }
    },
    created() {
        this.fetchProducts();
    }
};
</script>

<style scoped>
h1 {
    font-family: Arial, sans-serif;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin: 5px 0;
}
</style>